<ion-header>
  <div class="info-drop">  </div>
  <ion-toolbar class="desktop-visibility" *ngIf="isApkWebView">
    <div class="analytic-container">
      <div class="heading-part notificationheader">
        <ion-icon name="arrow-back-outline" class="back-button" (click)="goBack()"></ion-icon>
        <span class="heading">Analytics</span>
      </div>
    </div>
  </ion-toolbar>
  <ion-toolbar color="tertiary" class="mobile-visibility" [ngClass]="{'app-view-header': isApkWebView}">
      <div class="title-block title-block-custom">
          <div class="icon-title">
              <ion-icon name="arrow-back-outline" class="back-button" (click)="goBack()"></ion-icon>
              <ion-title class="title">Analytics</ion-title>
          </div>
      </div>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div class="analytic-container desktop-visibility">
    <div class="heading-part notificationheader">
      <ion-icon name="arrow-back-outline" class="back-button" (click)="goBack()"></ion-icon>
      <span class="heading">Analytics</span>
    </div>
  </div>
<div class="main-an">
  <div class="container1">
    <div *ngIf="holdingData.length == 0">
      <div class="no-record">
  <img src="assets/svg/no-record-oops.svg" alt="No Data" />
          <span>Oops! It looks like there’s no data available </span>
 </div>
  </div>
    <!-- <iframe #iframe *ngIf="holdingData.length > 0"></iframe> -->
    <iframe #iframe id="trendlyne" src="https://newsiifl.trendlyne.com/clientapi/irwin/webview/FHZVJwFXDC/portfolio-report/" *ngIf="holdingData.length > 0"  (load)="loadIframe()"></iframe>
    <div class="noData" *ngIf="holdingData.length == 0">
        No Data Found
    </div>
  </div>
</div>
</ion-content>