import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { URLS } from "../../../../config/api.config";
import { CommonService } from "../../../helpers/common.service";
import { environment } from "../../../../environments/environment";
import { HTTP } from "@awesome-cordova-plugins/http/ngx";
import { CustomEncryption } from "../../../../config/custom-encrypt";
import moment from "moment";
import { FormGroup } from "@angular/forms";

@Injectable()
export class GuestDashBoardService {
	public options: any = environment['optionalHeaders'];
	private nativeHeaders: any = environment['nativeHeaders'];
	private gatewaySubscriptionKey = environment['gatewaySubscriptionKey'];
	private globalCheckSumkey = environment['globalChecksumKey']

	private userOnboard = URLS.userOnboard;
	private leadSearchToken = URLS.crmLogin;			
	private verifypan = URLS.verifyPan;
	private guestDetails = URLS.guestDetails;
	private GuestCaptureDetails = URLS.GuestCaptureDetails;
	private captureGuestPayment = URLS.captureGuestPayment;

	constructor(private httpClient: HttpClient,
		private nativeHttp: HTTP,
		private commonService: CommonService,
		private cipherText: CustomEncryption
	) {
	}

	public getUserOnboardData(mob: any): Observable<{}> {
		let params = {
			"MobileNo": mob,
			"Key": this.userOnboard.key,
			"AppName": this.userOnboard.appName,
		}
		let obj: any = {};
		if (this.commonService.isApp()) this.nativeHttp.setDataSerializer('json');
		return this.commonService.isApp() ? from(this.nativeHttp.post(this.userOnboard.url, params, Object.assign(obj, this.nativeHeaders, this.options))).pipe(map(response => {
			return JSON.parse(response['data'] as any);
		})) : this.httpClient.post(this.userOnboard.url, params, { headers: new HttpHeaders(obj) });
	}

	public getTokenforPANVerification(): Observable<{}> {
		let params = new URLSearchParams();
		params.set("grant_type", 'password');
		params.set("username", 'IiflOnBoarding');
		params.set("password", 'AHx1InWid6tsi194sEwDBhbFAGjmHutbsy8r5eMwVm+xMk3fI6NrGoaRMnQCzNZiYA==');
	  

	let obj: any = {
		'Ocp-Apim-Subscription-Key': this.gatewaySubscriptionKey.eaccount,
		'appID': localStorage.getItem('appID') || '',
		'Content-Type': 'application/x-www-form-urlencoded'
	}

	if (this.commonService.isApp()) this.nativeHttp.setDataSerializer('urlencoded');
	return this.commonService.isApp() ? from(this.nativeHttp.post(`${this.verifypan.url}token`, params.toString(), Object.assign(obj, this.nativeHeaders, this.options))).pipe(map(response => {
		return JSON.parse(response['data'] as any);
	})) : this.httpClient.post(`${this.verifypan.url}token`, params.toString(), { headers: new HttpHeaders(obj) });
	}

	public verifyPanNo(data: any, cookievalue: string) {
		let params = {
			head: {
				"UniqueKey": "MFRSLO04",
				"AppName": this.verifypan.appName,
				"AppVer": this.verifypan.appVer,
				"OsName": this.verifypan.osName
			}, "body": data
		}
		let obj: any = {
			'Ocp-Apim-Subscription-Key': this.gatewaySubscriptionKey.eaccount,
			'appID': localStorage.getItem('appID') || '',
			'Authorization' : 'Bearer ' + cookievalue
		}


		if (this.commonService.isApp()) this.nativeHttp.setDataSerializer('json');
		return this.commonService.isApp() ? from(this.nativeHttp.post(`${this.verifypan.url}api/FranchiseeOnBoardingAPI/CheckPanExistV2`, params, Object.assign(obj, this.nativeHeaders))).pipe(map(response => {
			return JSON.parse(response['data'] as any);
		})) : this.httpClient.post(`${this.verifypan.url}api/FranchiseeOnBoardingAPI/CheckPanExistV2`, params, { headers: new HttpHeaders(Object.assign(obj)) });
	}

	public guestSaveDetails(data: any, formName: any) {
		let checkSumKey = this.cipherText.generateCheckSumKey();
		let stringValue = ''
		let urlEndPoint = ''
		if (formName == 'basicDetails') {
			stringValue = `${data.PanNo}_${data.FullName}_${data.FatherName}_${data.EmailID}_${data.MobileNumber}_${checkSumKey}_${moment(new Date()).format('DDMMYYYY')}`
			data.PanNo = this.cipherText.createChecksum(data.PanNo, checkSumKey);
			data.DOB = moment(data.DOB).format('DDMMYYYY');
		    data.MobileNumber = this.cipherText.createChecksum(data.MobileNumber, checkSumKey);
			urlEndPoint = 'SaveGuestBasicDetails'
		} else if (formName == 'bankDetails') {
			stringValue = `${data.MobileNumber}_${data.AccountNumber}_${data.IFSCCode}_${checkSumKey}_${moment(new Date()).format('DDMMYYYY')}`
			data.MobileNo = this.cipherText.createChecksum(data.MobileNumber, checkSumKey);
			urlEndPoint = 'SaveGuestBankdetails'
		} else if (formName == 'addressDetails') {
			stringValue = `${data.MobileNumber}_${data.Country}_${data.City}_${data.State}_${checkSumKey}_${moment(new Date()).format('DDMMYYYY')}`
			data.MobileNumber = this.cipherText.createChecksum(data.MobileNumber, checkSumKey);
			urlEndPoint = 'SaveGuestAddressdetails'
		}

		const checkSumValue = this.cipherText.createChecksum(stringValue, this.globalCheckSumkey);


		let params = {
			"head": {
				"RequestCode": "SaveGuestBasicDetails",
				"Key": this.guestDetails.key,
				"AppName": this.guestDetails.appName,
				"AppVer": this.guestDetails.appVer,
				"OsName": this.guestDetails.osName,
				"CheckSum": checkSumValue,
				"CheckSumKey": checkSumKey,
				"Token": localStorage.getItem('JWTToken')
			},
			"body": data
		};

		let obj: any = {
			'Ocp-Apim-Subscription-Key': this.gatewaySubscriptionKey.aaa,
			'appID': localStorage.getItem('appID') || ''
		};

		if (this.commonService.isApp()) this.nativeHttp.setDataSerializer('json');
		return this.commonService.isApp() ? from(this.nativeHttp.post(`${this.guestDetails.url}${urlEndPoint}`, params, Object.assign(obj, this.nativeHeaders))).pipe(map(response => {
			return JSON.parse(response['data'] as any);
		})) : this.httpClient.post(`${this.guestDetails.url}${urlEndPoint}`, params, { headers: new HttpHeaders(Object.assign(obj)) });
	}

	public searchLeadToken(){
		let params = {};
		let obj = {
			'Authorization' : 'Basic NUU2QzU5MjYtNTk1QS00Q0FGLTkyQzAtQUQzMTUzNDQwNDBCOmFwcEZnTEVkTGM4NTlDNUs=',
			'UserKey' : "Kdf7mqU0wVotAkRYuaVsAuT5RBXHyXcKy0nie92XFxmlnebKhoW545HLVLEEBKsi7Ee6HaMGwIMaEShXzbmGKJJ4wfLBzScEJQqn",
			'Ocp-Apim-Subscription-Key' : this.gatewaySubscriptionKey.iiflcrm,
			'appID': localStorage.getItem('appID') || ''
		};
		if (this.commonService.isApp()) this.nativeHttp.setDataSerializer('json');
		return this.commonService.isApp() ? from(this.nativeHttp.post(this.leadSearchToken.url, params, Object.assign(obj, this.nativeHeaders))).pipe(map(response => {
			return JSON.parse(response['data'] as any);
		})) : this.httpClient.post(this.leadSearchToken.url, params, { headers: new HttpHeaders(Object.assign(obj))});
	}

	public guestCaptureStatus(endPoint: string, requestCode: string) {
		let mobileNo = localStorage.getItem('GuestMobileNumber')
		let checkSumKey = this.cipherText.generateCheckSumKey();
		let stringValue = `${mobileNo}_${checkSumKey}_${moment(new Date()).format('DDMMYYYY')}`
		const checkSumValue = this.cipherText.createChecksum(stringValue, this.globalCheckSumkey);
		mobileNo = this.cipherText.createChecksum(mobileNo, checkSumKey);

		let params = {
			"head": {
				"RequestCode": requestCode,
				"Key": this.GuestCaptureDetails.key,
				"AppName": this.GuestCaptureDetails.appName,
				"AppVer": this.GuestCaptureDetails.appVer,
				"OsName": this.GuestCaptureDetails.osName,
				"CheckSum": checkSumValue,
				"CheckSumKey": checkSumKey,
				"Token": localStorage.getItem('JWTToken')
			},
			"body": {
				'Mobilenumber': mobileNo
			}
		};

		let obj: any = {
			'Ocp-Apim-Subscription-Key': this.gatewaySubscriptionKey.aaa,
			'appID': localStorage.getItem('appID') || ''
		};

		if (this.commonService.isApp()) this.nativeHttp.setDataSerializer('json');
		return this.commonService.isApp() ? from(this.nativeHttp.post(`${this.GuestCaptureDetails.url}${endPoint}`, params, Object.assign(obj, this.nativeHeaders))).pipe(map(response => {
			return JSON.parse(response['data'] as any);
		})) : this.httpClient.post(`${this.GuestCaptureDetails.url}${endPoint}`, params, { headers: new HttpHeaders(Object.assign(obj)) });
	}

	capturePayment(data:any){
		let checkSumKey =  this.cipherText.generateCheckSumKey();
		const stringValue = `${localStorage.getItem('GuestMobileNumber')}_${data.PaymentID}_${data.OrderID}_${data.Status}_${data.Amount}_${checkSumKey}_${moment(new Date()).format('DDMMYYYY')}`
		let checkSum = this.cipherText.createChecksum(stringValue,this.globalCheckSumkey)
		data.MobileNo = this.cipherText.createChecksum(localStorage.getItem('GuestMobileNumber'), checkSumKey);

	let params = { 
			"head": {
				"RequestCode": "SaveGuestPaymentdetails",
				"Key": this.GuestCaptureDetails.key,
				"AppName": "AAA",
				"AppVer": "01",
				"OsName": "Android",
				"CheckSum": checkSum,
				"CheckSumKey": checkSumKey,
				"Token":localStorage.getItem('JWTToken')
			},
			"body": data
		}
		let obj: any = {
			'Ocp-Apim-Subscription-Key': this.gatewaySubscriptionKey.aaa,
			'appID': localStorage.getItem('appID') || ''
		};

		if (this.commonService.isApp()) this.nativeHttp.setDataSerializer('json');
		return this.commonService.isApp() ? from(this.nativeHttp.post(`${this.captureGuestPayment.url}`, params, Object.assign(obj, this.nativeHeaders))).pipe(map(response => {
			return JSON.parse(response['data'] as any);
		})) : this.httpClient.post(`${this.captureGuestPayment.url}`, params, { headers: new HttpHeaders(Object.assign(obj)) });
	}
}
