import { environment } from '../environments/environment';


export const URLS = Object({
	
	checkSubscription: {			// not being used
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}AAASubscriptionStatus`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	addSpan: {
		url: `${environment.gatewayBaseUrl}${environment.ttAppBo}AddMarginDetails`,
		key: 'dL8mG4UwCfVYjzPWu8Rp'
	},
	calculateSpan: {
		url: `${environment.gatewayBaseUrl}${environment.ttAppBoV1}SpanMarginCalculation`,
		key: 'PMVHB5wpK7LCCYBkl6APNYMqvyNWMZKl'
	},
	marginDetails: {
		url: `${environment.gatewayBaseUrl}${environment.ttAppBoV1}GetMargin`,
		key: 'PMVHB5wpK7LCCYBkl6APNYMqvyNWMZKl'
	},
	delMargin: {
		url: `${environment.gatewayBaseUrl}${environment.ttAppBo}DeleteMarginDetails`,
		key: 'dL8mG4UwCfVYjzPWu8Rp'
	},
	resetMargin: {
		url: `${environment.gatewayBaseUrl}${environment.ttAppBo}ResetMargin`,
		key: 'dL8mG4UwCfVYjzPWu8Rp'
	},
	forgotPass: {
		url: `${environment.gatewayBaseUrl}${environment.ttAppBo}ForgotManagerPassword`,
		key: 'dL8mG4UwCfVYjzPWu8Rp'
	},
	swarajLogout: {
		url: `${environment.gatewayBaseUrl}${environment.ttUserActivity}LogOut`,
		key: 'PMVHB5wpK7LCCYBkl6APNYMqvyNWMZKl'
	},
	backofficeLogout: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBackOffice}EmployeeLogoutV1`,
		key: ''
	},
	swarajCookie: {			// no usage in code. check
		url: `${environment.gatewayBaseUrl}${environment.ttUserActivity}LoginRequestv2`,
		key: 'PMVHB5wpK7LCCYBkl6APNYMqvyNWMZKl'
	},
	backofficeCookie: {		// no usage in code. check
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}LoginAAAV2`,
		key: ''
	},
	mfAccountReportDownload: {
		url: `${environment.gatewayBaseUrl}${environment.mfApp}GetAccountStatementOfSD`,			//  MF
		vid: 'HK20SR5AC30'
	},
	narnolia:{
		key:'PMVHB5wpK7LCCYBkl6APNYMqvyNWMZKl',
		appSourceNarnolia:'4612',
		appSource:'298'
	},
	grobox: {
		appSource: '274',
		url:'https://grobox-uat.trendlyne.com/grobox/api/auth/login/iifl/dealer/'
	},
	isAgreeCookie: {			// no usage in code. check
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}ISAgreed`,
		key: ''
	},
	holdingCookie: {			// no usage in code. check
		url: `${environment.gatewayBaseUrl}${environment.ttUserActivity}MyHolding`,
		key: ''
	},
	marketWatchList: {
		url: `${environment.gatewayBaseUrl}${environment.ttMarket}GetNewMarketWatchV3`,
		key: 'PMVHB5wpK7LCCYBkl6APNYMqvyNWMZKl'
	},
	getMWatchList: {
		url: `${environment.gatewayBaseUrl}${environment.ttMarket}GetMWatchList`,
		key: 'PMVHB5wpK7LCCYBkl6APNYMqvyNWMZKl'
	},
	getMFeedList: {
		url: `${environment.gatewayBaseUrl}${environment.ttAppBo}v4/MarketFeed`,
		key: 'dL8mG4UwCfVYjzPWu8Rp'
	},
	deleteScrip: {
		url: `${environment.gatewayBaseUrl}${environment.ttAppBo}SaveMWV1`,
		key: ''
	},
	setDefault: {				// code present, but not called. check
		url: `${environment.gatewayBaseUrl}${environment.ttAppBo}ChangeWLAttribute`,
		key: ''
	},
	clientList: {
		url: `${environment.gatewayBaseUrl}${environment.ttAppBo}ManagerWiseClientHoldingTrades`,
		key: 'dL8mG4UwCfVYjzPWu8Rp'
	},
	userType: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}GetUserTypeRequestV1`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	request1: {					// no usage in code. check
		url: `${environment.gatewayBaseUrl}${environment.ttUserActivity}LoginRequestV2`,
		key: 'PMVHB5wpK7LCCYBkl6APNYMqvyNWMZKl'
	},
	request2: {					// no usage in code. check
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}LoginAAAV2`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	rmProfile: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}ProfileDetails`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	foliowise: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}ClientMFIIFLData`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	dashboardReport: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}ClientWireDashboardreport`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	mapRM: {
		url: `${environment.gatewayBaseUrl}${environment.ttClient}ManagerMappingv1`,
		key: 'PMVHB5wpK7LCCYBkl6APNYMqvyNWMZKl'
	},
	request3: {
		url: `${environment.gatewayBaseUrl}ClientLogin`,		// review. less hits confirm with aaa backend, not configured
		key: 'PMVHB5wpK7LCCYBkl6APNYMqvyNWMZKl'
	},
	subBroker: {				// no usage in code. check		
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}SubBrokerLoginRequestV4`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	subBrokerMap: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}GetClientSubBrokerMappingRequestV1`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	gainers: {
		url: `${environment.gatewayBaseUrl}${environment.ttAppBo}GainerLooser`,
		key: ''
	},
	changePswd: {		// no usage in code. check
		url: `${environment.gatewayBaseUrl}${environment.ttUserActivity}ChangePassword`,
		key: 'PMVHB5wpK7LCCYBkl6APNYMqvyNWMZKl'
	},
	isAgree: {			// no usage in code. check
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}ISAgreed`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	getDates: {
		url: `${environment.gatewayBaseUrl}${environment.ttAppBo}FutureScripDetails`,
		key: ''
	},
	getOptionsDate: {
		url: `${environment.gatewayBaseUrl}${environment.ttAppBo}OptionsScripExpiry`,
		key: ''
	},
	getOptionsDetails: {
		url: `${environment.gatewayBaseUrl}${environment.ttAppBo}OptionScripDetails`,
		key: ''
	},
	holding: {			// no usage in code. check
		url: `${environment.gatewayBaseUrl}${environment.ttUserActivity}HoldingAAA`,
		key: ''
	},
	tradeFor: {			// no usage in code. check
		url: `${environment.gatewayBaseUrl}${environment.ttTrading}TradeBookV1`,
		key: '' 
	},
	superstar: {
		url: `${environment.gatewayBaseUrl}${environment.aaaSearch}superstarsStock/`, // no usage in code. check
		key: 'eab16d83a9d0f05f9dd9d003a51a816781a67632'
	},
	superStarIndex: {
		url: `${environment.gatewayBaseUrl}getSuperStarIndex/`,			// not in use, not configured on new gateway
		key: 'eab16d83a9d0f05f9dd9d003a51a816781a67632'
	},
	superStarShares: {
		url: `${environment.gatewayBaseUrl}custom/?superStarName=`,		// not in use, not configured on new gateway
		key: '2cccc59bdab77bace6189d001f96487e'
	},
	superStarportfolio: {
		url: `${environment.gatewayBaseUrl}`,							// not in use, not configured on new gateway
		key: 'eab16d83a9d0f05f9dd9d003a51a816781a67632'		
	},
	superStarHistory: {													// not in use, not configured on new gateway
		url: `${environment.gatewayBaseUrl}`,
		key: 'eab16d83a9d0f05f9dd9d003a51a816781a67632'
	},
	cashfilter: {
		url: `${environment.gatewayBaseUrl}${environment.ttAppBo}CashFilter`,
		key: ''
	},
	cashtabdetails: {
		url: `${environment.gatewayBaseUrl}${environment.ttAppBo}CompanyDetailPage`,
		key: ''
	},
	marketdepth: {
		url: `${environment.gatewayBaseUrl}${environment.ttAppBo}MarketDepth`,
		key: ''
	},
	futureOptDetails: {
		url: `${environment.gatewayBaseUrl}${environment.papiDerivative}FutOptIndvidualStockDetail-version2/`,
		key: ''
	},
	updateFutureOptDetails: {
		url: `${environment.gatewayBaseUrl}${environment.ttCompanyInfo}CompanyDetailPageV1`,
		key: 'PMVHB5wpK7LCCYBkl6APNYMqvyNWMZKl'
	},
	cashscripcode: {
		url: `${environment.gatewayBaseUrl}${environment.ttAppBo}GetCashScripCode`,
		key: 'dL8mG4UwCfVYjzPWu8Rp'
	},
	getoptionforsymbol: {
		url: `${environment.gatewayBaseUrl}${environment.ttAppBo}GetOptionsForSymbol`,
		key: ''
	},
	contractinfo: {
		url: `${environment.gatewayBaseUrl}${environment.ttScrip}ScripDetailsFoFOOrderV3`,
		key: 'PMVHB5wpK7LCCYBkl6APNYMqvyNWMZKl'
	},
	newstabList: {
		url: `${environment.newsinfoUrl}core/home-page/company-news1/page/1/perpage/15/symbol/`,
		key: ''
	},
	newstabDetails: {
		url: `${environment.newsinfoUrl}core/home-page/article-content/articleid/`,
		key: ''
	},

	weekhighlow: {
		url: `${environment.gatewayBaseUrl}${environment.papiMarket}52weekhighlow-version2/`,
		key: ''
	},
	futGain: {
		url: `${environment.gatewayBaseUrl}${environment.papiDerivative}fogainerlooser-version2`,
		key: ''
	},
	futOIGain: {
		url: `${environment.gatewayBaseUrl}${environment.papiDerivative}FOOGainerLooser-version2`,
		key: ''
	},
	rollOver: {
		url: `${environment.gatewayBaseUrl}${environment.papiDerivative}RollOverPercentage-version2`,
		key: ''
	},
	deliveryPer: {
		url: `${environment.gatewayBaseUrl}${environment.papiMarket}DeliveryPerc-version2`,
		key: ''
	},
	iglScore: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}GetSubBrokerIGLCScore`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	premiumPercent: {
		url: `${environment.gatewayBaseUrl}${environment.papiDerivative}PremiumPercent-Version2`,
		key: ''
	},
	mostActiveSTK: {
		url: `${environment.gatewayBaseUrl}${environment.papiDerivative}mostactivestockandindex-version2`,
		key: ''
	},
	scripSymbol: {				// no usage in code. check
		url: `${environment.gatewayBaseUrl}${environment.ttAppBo}GetScripsNDSymbol`,
		key: ''
	},
	getExpiryForsymbol: {		// no usage in code. check
		url: `${environment.gatewayBaseUrl}${environment.ttAppBo}GetExpiryForSymbolOptions`,
		key: ''
	},
	getScripListForSymbol: {	// no usage in code. check
		url: `${environment.gatewayBaseUrl}${environment.ttAppBo}GetCompanyNameForSymbol`,
		key: ''
	},
	bulkblockdeal: {
		url: `${environment.gatewayBaseUrl}${environment.papiMobileAPI}bulkdeals-version2/`,
		key: ''
	},
	voltopper: {
		url: `${environment.gatewayBaseUrl}${environment.ttAppBo}v3/TopTraded`,
		key: 'dL8mG4UwCfVYjzPWu8Rp'
	},
	// searchURL
	equityCash: {
		url: `${environment.gatewayBaseUrl}${environment.ttAppBo}v2/SearchScrip`,
		key: ''
	},
	equityFutureOpt: {
		url: `${environment.gatewayBaseUrl}${environment.ttAppBo}v2/SearchFOScrip`,
		key: ''
	},
	marketfeedsearch: {
		url: `${environment.gatewayBaseUrl}${environment.ttAppBo}v3/MarketFeed`,
		key: ''
	},
	commodityMarketfeed: {
		url: `${environment.gatewayBaseUrl}${environment.ttAppBo}MarketFeed`,
		key: ''
	},
	currencyFutureOpt: {
		url: `${environment.gatewayBaseUrl}${environment.ttAppBo}FOCurrencyScripList`,
		key: ''
	},
	commodityFuture: {
		url: `${environment.gatewayBaseUrl}${environment.ttAppBo}CommodityScripListIPAD`,
		key: ''
	},
	// Details for future
	searchFutureDetails: {
		url: `${environment.gatewayBaseUrl}${environment.ttAppBo}FutureScripDetails`,
		key: ''
	},
	searchFutCommoDetails: {
		url: `${environment.gatewayBaseUrl}${environment.ttAppBo}CommScripDetails`,
		key: ''
	},
	// Details for future
	searchOptionExpiry: {
		url: `${environment.gatewayBaseUrl}${environment.ttAppBo}OptionsScripExpiry`,
		key: ''
	},
	searchOptionScripDetail: {
		url: `${environment.gatewayBaseUrl}${environment.ttAppBo}OptionScripDetails`,
		key: ''
	},
	// Client & Trades API
	clientHoldingMargin: {
		url: `${environment.gatewayBaseUrl}${environment.ttAppBo}GetClientHoldingAndMarginDetails`,
		key: 'dL8mG4UwCfVYjzPWu8Rp'
	},
	amcReport: {
		url: `${environment.gatewayBaseUrl}${environment.mfApp}ConsolidatedAccountStmt`,				// MF
		vid: 'HK20SR5AC30',
		authHeader: 'Basic ' + btoa('+Ziie2ZAI4Y=:wEBYb4fMPXalOE/vdth5Tg==')
	},
	orderBook: {
		url: `${environment.gatewayBaseUrl}${environment.ttTrading}OrderBookNew`,
		key: 'PMVHB5wpK7LCCYBkl6APNYMqvyNWMZKl'
	},
	tradeBook: {
		url: `${environment.gatewayBaseUrl}${environment.ttTrading}TradeBookV1`,
		key: 'PMVHB5wpK7LCCYBkl6APNYMqvyNWMZKl'
	},
	marginV2: {
		url: `${environment.gatewayBaseUrl}${environment.ttTrading}MarginV2`,
		key: 'PMVHB5wpK7LCCYBkl6APNYMqvyNWMZKl'
	},
	clientLedger1: {
		url: `${environment.gatewayBaseUrl}${environment.ttAppBo}v1/Ledger`,
		key: 'PMVHB5wpK7LCCYBkl6APNYMqvyNWMZKl'
	},
	clientHolding: {
		url: `${environment.gatewayBaseUrl}${environment.ttUserActivity}MyHolding`,
		key: 'PMVHB5wpK7LCCYBkl6APNYMqvyNWMZKl'
	},
	clientNetPositioneq: {
		url: `${environment.gatewayBaseUrl}${environment.ttStockDetail}GetEquityTradeData`,
		key: 'PMVHB5wpK7LCCYBkl6APNYMqvyNWMZKl'
	},
	clientNetPositioncomm: {
		url: `${environment.gatewayBaseUrl}${environment.ttStockDetail}GetCommodityTradedata`,
		key: 'PMVHB5wpK7LCCYBkl6APNYMqvyNWMZKl'
	},
	clientHoldingPL: {
		url: `${environment.gatewayBaseUrl}${environment.ttClient}HoldingPLSummaryV2`,
		key: 'PMVHB5wpK7LCCYBkl6APNYMqvyNWMZKl'
	},
	
	// Client & Trades for Consolidate
	consolidateHolding: {
		url: `${environment.gatewayBaseUrl}${environment.ttUserActivity}HoldingAAA`,
		key: 'PMVHB5wpK7LCCYBkl6APNYMqvyNWMZKl'
	},
	consolidateCommodityHolding: {
		url: `${environment.gatewayBaseUrl}${environment.ttUserActivity}HoldingCommAAA`,
		key: 'PMVHB5wpK7LCCYBkl6APNYMqvyNWMZKl'
	},
	consolidateTradeBook: {
		url: `${environment.gatewayBaseUrl}${environment.ttTrading}TradeBookForAAA`,
		key: 'PMVHB5wpK7LCCYBkl6APNYMqvyNWMZKl'
	},
	consolidateOrderBook: {
		url: `${environment.gatewayBaseUrl}${environment.ttAppBo}OrderBookForAAA`,
		key: 'dL8mG4UwCfVYjzPWu8Rp'
	},
	fundsPayinOut: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}PayinPayoutDetails`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	offlineClients: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}OfflineMFClients`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	amcData: {
		url: `${environment.gatewayBaseUrl}${environment.mfApp}GetAMCData`						// MF
	},
	dashboarddetail: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}DashboardDetail`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	aumdetail: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}AUMdetails`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	overallAum: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}OverallAUMClientwise`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	aumEquity: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}EquityAUMClientwise`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	aumMutualFund: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}MFAUMClientwise`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	aumFDBond: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}GetFDBondNCDAUMClientwiseDetails`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	aumPMS: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}GetPMSAIFAUMClientwiseDetails`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	aumMLDS: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}GetMLDPTCAUMClientwiseDetails`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	sipBook: {			// no usage in code. Check
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}SIPDetails`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	sipLive: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}SIPClientwiseDetails`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	bouncedCeased: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}GetAAABouncedCeasedSIP`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	insuranceSummary: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}GetAAAInsuranceSummary`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	insuranceDetails: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}GetAAAInsuranceDetails`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	FDSMatureDetails: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}GetAAABookedMaturedFD`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	clientDetail: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}TotalClientsDetails`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	clientDetailList: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}ViewAllClients`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	fetchClientDashboardDetails: {
		url: `${environment.gatewayBaseUrl}${environment.aaaDashboard}FetchClientDashBoardDetails`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	brokerageDetails: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}BrokerageDetails`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	brokerageDetailsList: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}ClientWiseBrokerage`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	commSummaryList: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}GetCommClientSummary`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	brokerageDue: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}BrokerageDue`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	hierarchyList: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}GetRMHierarchyV1`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	// Indices
	indiceMaster: {
		url: `${environment.gatewayBaseUrl}${environment.ttAppBo}v2/IndiceMasterNew`,
		key: ''
	},
	currCommoIndices: {
		url: `${environment.gatewayBaseUrl}${environment.papiMarket}CurrencyDashboardList`,
		key: ''
	},
	globalMarketIndices: {
		url: `${environment.gatewayBaseUrl}aaa/WebApi/indices#global_indices`,
		key: ''
	},
	dashHeader: {
		url: `${environment.gatewayBaseUrl}${environment.ttAppBo}LoginDashboard`,
		key: ''
	},
	indiDetails: {
		url: `${environment.gatewayBaseUrl}${environment.ttAppBo}IndicesDetailPage`,
		key: ''
	},
	//Notification center
	notification: {
		url: `${environment.gatewayBaseUrl}${environment.ttAppBo}v3/NotificationCenter`,
		key: 'dL8mG4UwCfVYjzPWu8Rp'
	},
	// Market Status
	exchStatus: {
		url: `${environment.gatewayBaseUrl}${environment.ttStockDetail}GetExchengeStatus`,
		key: 'PMVHB5wpK7LCCYBkl6APNYMqvyNWMZKl'
	},
	pledgeSecurity: {
		url: `https://swarajuat.iifl.in/TradeWeb/frmPledgeSecurities.aspx`
	},
	// Terms & Condition
	termCondition: {
		url: `${environment.gatewayBaseUrl}${environment.ttDataInfo}TermsAndConditionV1`,
		key: 'PMVHB5wpK7LCCYBkl6APNYMqvyNWMZKl'
	},
	//Exposure List
	exposureList: {
		url: `${environment.gatewayBaseUrl}${environment.ttAppBo}GetCategoryExposure`,
		key: 'dL8mG4UwCfVYjzPWu8Rp'
	},
	exposureDescription: {
		url: `${environment.gatewayBaseUrl}${environment.ttAppBo}GetCategoryScriptInfo`,
		key: 'dL8mG4UwCfVYjzPWu8Rp'
	},
	payoutFDDetails: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}GetAAAFDPayoutDetails`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	payoutBondsDetails: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}GetBondPayout`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	payoutPmsDetails: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}GetAAAPMSPayout`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	payoutMfDetails: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}GetMFPayout`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	ClientWiseMFPayDetail: {
		url: `${environment.gatewayBaseUrl}${environment.aaaReport}ClientWisePayDetail`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	payoutMldDetails: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}GetAAAMLDPayout`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	payoutNcdDetails: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}GetAAANCDPayout`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	payoutInsuranceDetails: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}GetAAANewRenewInsurancePayout`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	payoutAifDetails: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}GetAAAAIFPayout`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	payoutPtcDetails: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}GetAAAPTCPayout`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	totalPayoutDetails: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}TotalPayoutDetails`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	eqPayoutDetails: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}GetAAAEquityPayout`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	summaryReport: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}GetFanSubBrokerPayoutDetailsV1`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`,
		Authkey:"53D7AE755B82D5C2"
	},
	dpTransaction: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}DPTransaction`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	// limit request status
	limitReqStatus: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}LimitReqStatus`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	// jv request status
	jvReqStatus: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}JVRequestStatus`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	// client Code for limit and Jv Status
	clientCodesList: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}GetClientCodes`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	//limit insert
	limitInsert: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}LimitReqInsert`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	limitValidate: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}LimitReqValidate`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	//jv validate
	jvValidate: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}JVValidate`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	editIpDetail: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}EditFetchStaticIP`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	getIpDetails: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}FetchStaticIP`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	jvInsert: {
		url: `${environment.gatewayBaseUrl}${environment.aaaJV}InsertJVRequest`,
		key: `${environment.paramsAPIKey}`,
        appName: `${environment.boAppName}`,
        appVer: "01",
        osName: "Android",
	},
	//borkerge Request
	brokerageApprove: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}JVBrokeragApprovalRights`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`	
	},
	brokergeRequest:{
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}BrokerageRequestStatus`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`	
	},
	brokergeRequestV1:{
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}BrokerageRequestStatus_V1`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`	
	},
	

	//share-deposit
	subBrokerShareDepositDetailsV1:{
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}GetFanSubBrokerShareDepositDetailsV1`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`	
	},
	equityDeposit: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}GetFanSubBrokerEquityDepositDetailsV1`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`,
		Authkey:"53D7AE755B82D5C2",
	},
	brokerageLedger: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}GetPartnerLedgerDetailsV1`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`,
		Authkey:"APIBO52UCVDWFY",
	},
	// brokerge Insert
	brokerageReqInsert:{
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}BrokerageRequestInsert`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`	
	},
	//NFDCRiskReport
	nfdcRiskReport:{
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}NFDCRiskReport`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`	
	},
	scriptExcel:{
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}ScripMasterDetails`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`	
	},
	//RaaDebit
	RaaDebit:{
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}RAADebitReport`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`	
	},
	//BOD Holding
	BodHolding:{
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}HoldingDetails`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`	
	},
	//BOD Header
	BodHeader:{
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}Clientdetailsheader`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`	
	},
	FnoPositions:{			// no usage in code. Check
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}FNOPositions`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`	
	},
	SlbmHoldings:{			// no usage in code. Check
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}SLBMHoldings`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`	
	},
	//client-summary
	clientSummary:{
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}ClientSummary`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`	
	},
	editClientSummary:{
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}EditClientSummary`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`	
	},
	//client-details-profile-data
	profileDetailsV1:{
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}ProfileDetails_V1`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`	
	},
	//client-pan 
	clientPanNo:{
		url: `${environment.gatewayBaseUrl}${environment.mfApp}GetClientPanNoV1`,
		vid:'HK20SR5AC30',
		appName: `${environment.boAppName}`	
	},
	//Brokerage-Info
	brokerageInformation:{
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}GetBrokerageInformation`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`	
	},
	//TradeListing
	tradeListing:{
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}TradeListing`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`	
	},
	settlement: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}GetSettlementDetails`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	//unrealized p&l
	unRealizedPL:{
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}PnLSummaryUnRealized`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`	
	},
	// realized p&l
	realizedPL:{
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}PnLSummaryRealizedv1`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`	
	},
	//Fund-transfer Module
	//AvailableAmountForPayout
	availableFund: {
		url: `${environment.gatewayBaseUrl}${environment.ttAppBo}AvailableAmountForPayout`,
		key: '3dddd60bdab77bace6189d001f96498f',
		appName: "IIFLMFMOB",	
	},
	// Inter Ledger Transfer
	interLedgerTransfer: {			// no usage in code. Check
		url: `${environment.gatewayBaseUrl}${environment.ttFundTransfer}RequestInterLedgerTransferAAA`,
		key: 'PMVHB5wpK7LCCYBkl6APNYMqvyNWMZKl',
		appName: "IIFLMarkets",	
	},
	//FundPayoutDetails
	fundPayoutDetail: {
		url: `${environment.gatewayBaseUrl}${environment.ttAppBo}v2/FundPayoutDetails`,
	},
	//AvailableAmountForInterTransfer
	amountForInterTransfer: {
		url: `${environment.gatewayBaseUrl}${environment.ttAppBo}AvailableAmountForInterTransfer`,
		key: '3dddd60bdab77bace6189d001f96498f',
		appName: "IIFLMFMOB",	
	},

	//dpScript
	dpScript: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}GetDpScriptPayout`,
		key: `${environment.paramsAPIKey}`,
appName: `${environment.boAppName}`
	},

	//PayoutRequestAAA
	payOutRequest: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}SavePayoutRequestV1`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`	
	},
	//PayoutHistory
	payoutHistory: {
		url: `${environment.gatewayBaseUrl}${environment.ttAppBo}PayoutHistory`
	},
	//Branch List in Client Summary
	BranchMapping: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}GetRMBranchMapping`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	//approved and reject in brokerage Request List
	brokApproRej: {				// no usage in code. Check
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}BrokerageApproveReject`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	
	sipOrderBook: {
		url: `${environment.gatewayBaseUrl}${environment.mfApp}v3/SIPOrderBook`,
		vid: 'HK20SR5AC30',
		appName: `${environment.boAppName}`
	},

	orderBookMf: {
		url: `${environment.gatewayBaseUrl}${environment.mfApp}v3/OrderBook`,
		vid: 'HK20SR5AC30',
		appName: 'Mutual Funds by IIFL'
	},
	statAcc: {
		url: `${environment.gatewayBaseUrl}${environment.mfApp}AccountStatementCall`,
		vid: 'HK20SR5AC30',
		appName: `${environment.boAppName}`
	},
	dpHolding: {
		url: `${environment.gatewayBaseUrl}${environment.aaaDashboard}GetMFDPPortfolio`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	// API to check for product activation flag in wire request
	productActivationRights: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}ActiveDeactiveRightsBase`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	// API for brokerage Request Insert V1
	hybridBrokRequest: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}CurrentBrokerage`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	hybridBrokRequestV1: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}CurrentBrokerage_v1`,
		key: '446794970AAA1237ab394d176612f8c6',
		appName: 'AAA'
	},
	// hybrid brok on submit
	brokerageRequestInsertV1: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}BrokerageRequestInsertV1`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	//prod Activation
	actDecProduct: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}ActiveDeactiveProduct`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	//portfolio MF
	portfolioMf: {
		url: `${environment.gatewayBaseUrl}${environment.mfApp}V2/PortfolioAPICall`,			// MF
		vid: 'AS30RH5KC20',
		appName: `${environment.boAppName}`
	},
	loginAppInfo: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}IAppInfo`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	userOnboard:{
		url: `${environment.gatewayBaseUrl}${environment.aaaSearch}getDIYUserOnBoardData`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	loginValidateUser: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}validateUser`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	guestValidateOtp: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}GuestLoginValidateOTP`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	guestGenerateOtp: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}GuestLogin`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	loginVerifyUserOTP: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}VerifyUserOTP`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	loginVerifyUserCred: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}VerifyUserCredential`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	loginResetCredential: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}ResetCredential`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	loginForgetCred: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}ForgetCredential`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	loginForgetCredVarifyOTP: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}ForgetCredentialVarifyOTP`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	generateTokenForAAA: {
		url: `${environment.gatewayBaseUrl}${environment.ttUserActivity}GenerateTokenForAAA`,
		key: 'PMVHB5wpK7LCCYBkl6APNYMqvyNWMZKl',
		appName: 'IIFLMarkets'
	},
	// dashbord revamp
	partnerPoints: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}GetPartnerPoints`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	ipoList: {
		url: `${environment.gatewayBaseUrl}${environment.ipo}open-issues/ALL`,
	},
	clientCount: {
		onupUrl:'https://oneupuatcb.indiainfoline.com/issue/detail/code/',
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}FetchClevertapCounts`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	cmsDeposit: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}GetCMSDepositBankV1`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	equityCms: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}GetEquityCMSStatusV1`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	saveCmsEntry: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}SaveCMSEntryV1`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`	
	},
	oneUploginThrowApp: {
		url: `${environment.gatewayBaseUrl}${environment.ipo}OneUpLoginthrowOtherAppv2`,
	},
	ipoClientList: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}FetchClevertapDetails`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`		
	},
	getClientHoldingAbove25L: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}GetClientHoldingAbove25L`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	BusinessOppsFao: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}GetBusinessOpportunitiesFAO`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	clientWithoutSip: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}GetMFClientWithOutSIP`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	reportDownload: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBackOffice}reportEmailingv2`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	zentechContractNotes: {
		url: `${environment.gatewayBaseUrl}BackOfficeWebAPI/api/BackOffice/GetZentechContractnotes`,
	},
	exchangeCertificateURL : {
		url: `${environment.gatewayBaseUrl}${environment.aaaDashboard}GetExchangeCertification`,
		key: `${environment.paramsAPIKey}`,
        appName: `${environment.boAppName}`,
        AppVer: "01",
        OsName: "Android",
	},
	clientNotTraded: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}GetAAAClientNotTraded`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	clientTobeDormant: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}GetClientTobeDormant`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	getBrokeragePerformance: {
		url: `${environment.gatewayBaseUrl}${environment.aaaGroBox}GetBrokeragePerformanceDetailsV1`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	getNcdMfDebtHolding: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}GetAAANCDorMFDebtHolding`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	dayWiseBrokerageGraph: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}GetDayWiseBrokerageGraph`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	dashBoardCount: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}GetAAADashBoardCount`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	incentivesPremiaRMs:{
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}IncentivesPremiaRMs`,
		key: `${environment.paramsAPIKey}`,
appName: `${environment.boAppName}`
	},
	peerPoints: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}GetPeerPoints`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	ClientNotInMF: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}CllientsNotInvestedInMF`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	equityMfLeads: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}EquityMFLeads`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	bDayToday: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}GetListofBdayNext30day`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	p1p2p3Clients: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}GetListofP1P2P3Clients`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	leadStats: {
		url: `${environment.gatewayBaseUrl}${environment.iiflCRM}AAAClientSummaryDetails`,
		key: 'e33d222660f546a3be58421ca9a242dc'
	},
	fixedIncomeLeads: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}FixedIncomeLeads`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	PmsAifLeads: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}PMSAIFLeads`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	//Jv Request Status Option
	jvStatusOption: {
		url: `${environment.gatewayBaseUrl}${environment.aaaJV}ApproveRejectJVRequest`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`,
        appVer: "01",
        osName: "Android",
	},
	inflSendOtp: {
		url: `${environment.gatewayBaseUrl}${environment.ipo}SendOTP`,
	},
	inflVerifyOtp: {
		url: `${environment.gatewayBaseUrl}${environment.ipo}VerifyOTP`,
	},
	getInfluDetail: {
		url: `${environment.gatewayBaseUrl}GetInfluencerDetails`,		// not in use, not configured
	},
	crmToken: {
		// url: `${environment.baseUrl}ZohoToken`		old endpoint
		url: `${environment.gatewayBaseUrl}${environment.iiflCRM}Token`
	},
	createLead: {
		url: `${environment.gatewayBaseUrl}${environment.iiflCRM}Save`
	},
	findLead: {
		url: `${environment.gatewayBaseUrl}${environment.iiflCRM}LeadSearchAAA`
	},
	loginSearch:{
		url: `${environment.gatewayBaseUrl}${environment.iiflCRM}login`,
		userKey:'Kdf7mqU0wVotAkRYuaVsAuT5RBXHyXcKy0nie92XFxmlnebKhoW545HLVLEEBKsi7Ee6HaMGwIMaEShXzbmGKJJ4wfLBzScEJQqn'
	},
	mfRejectionData:{
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}MFRejectData`,
		key: `${environment.paramsAPIKey}`,
appName: `${environment.boAppName}`
	},
	createTicket: {
		url: `${environment.gatewayBaseUrl}${environment.iiflCRMWebAPI}CreateTicketinCRMCMMultiAttach`
	},
	searchTicket: {
		url: `${environment.gatewayBaseUrl}${environment.iiflCRMWebAPI}SearchTicketsinBRCS`
	},
	updateTkt: {
		url: `${environment.gatewayBaseUrl}${environment.iiflCRM}CreateTicketinCRMCustomModule`
	},
	updateRespondedTkt: {
		url: `${environment.gatewayBaseUrl}${environment.iiflCRM}CreateTicketinCRMCustomModuleV2`
	},
	brokingBot: {
		url: `${environment.gatewayBaseUrl}${environment.iiflCRM}BrokingBotRequest`
	},
	clientLedgerDetails: {			// no usage in code. check
		url: `${environment.gatewayBaseUrl}${environment.ttAppBo}v1/Ledger`,
		// key: 'PMVHB5wpK7LCCYBkl6APNYMqvyNWMZKl'
	},
	holdPhysicalFNOReports: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}SubmitHoldPhysicalFnOReport`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
		// key: 'PMVHB5wpK7LCCYBkl6APNYMqvyNWMZKl'
	},

	getPhysicalFNOReports: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}GetHoldPhysicalFnOReport`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
		// key: 'PMVHB5wpK7LCCYBkl6APNYMqvyNWMZKl'
	},
	getDPSchemeDetails: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}DPSchemeChargesDetails`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	getBeyongIRR:{
		url: `https://broking-apigateway.indiainfoline.com/beyondirr/prod/accounts/api/iifl/aaa/`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	ttMapping: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}TTNowmappingv1`,
		key: '446794970AAA1237ab394d176612f8c6'
	},
	scripSummaryReport: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}ScriptSummaryReport`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	clientProfileCap: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}GetCaptEngandProfCapt`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	searchZoho: {
		url: `${environment.gatewayBaseUrl}${environment.iiflCRMKbArticles}search`,
	},
	faqSearchZoho: {
		url: `${environment.gatewayBaseUrl}${environment.iiflCRM}AAAarticleList`,
	},
	crmLogin: {
		url: `${environment.gatewayBaseUrl}${environment.iiflCRM}login`,
	},
	attachToken: {
		url: `${environment.gatewayBaseUrl}${environment.iiflCRM}v2Login`
	},
	downloadAttachment: {
		url: `${environment.gatewayBaseUrl}${environment.iiflCRM}DownloadBrcsTicketFileById`,
	},
	listZoho: {
		url: `${environment.gatewayBaseUrl}${environment.iiflCRMKbArticles}`,		// not being used, not configured in new gateway
	},
	getLivlong: {
		url: `https://api-uat.iiflinsurance.com/authentication.web.api/api/v2/auth/verifyApiKey`,
		key: 'LL.655598262a94ecec7ec888cae32d4054c4e.AAA'
	},
	deskGetArticle: {
		// url: `${environment.baseUrl}execute`,		old endpoint
		url: `${environment.gatewayBaseUrl}${environment.iiflCRMFunctions}desk_get_article/actions/execute`,
	},
	iglcScore: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}IGLCScore`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}` //prod
	},
	flyhighData: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}FlyHigh`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	getRootCategoreTree: {
		// url: `${environment.baseUrl}executeV1`,		old endpoint
		url: `${environment.gatewayBaseUrl}${environment.iiflCRMFunctions}desk_get_categoretree/actions/execute`,
	},
	clientOutstanding: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}GetClientOutstandingReport`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	clientRiskProfileScore: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}ClientRiskProfileScore`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	getMarginShortfall:{
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}MarginShortFall`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	clientInteractionToken:{
		url: `${environment.gatewayBaseUrl}${environment.iiflCRM}login`
	},
	clientTicketDetails:{
		url: `${environment.gatewayBaseUrl}${environment.iiflCRM}AAAPastClientTicketDetails`
	},
	clientInteractionDetails:{
		url: `${environment.gatewayBaseUrl}${environment.iiflCRM}AAAPastClientInteractionDetails`
	},
	verifyOneLogin:{
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}VerifyOneLoginUserCredential`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	userInfoOneLogin: {
		url: `${environment.oneLoginOidc}oidc/2/me`
	},
	commodityrealtimereport:{
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}GetCommodityrealtimereport`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	//shared reports download 
	clientPortfolio: {
		url: `${environment.gatewayBaseUrl}${environment.aaa360}ClientPortfolioSummary`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	familyMapp: {
		url: `${environment.gatewayBaseUrl}${environment.aaa360}FamilyMapping`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	clientEqDetails: {
		url: `${environment.gatewayBaseUrl}${environment.aaa360}ClientEquityDetails`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	// clientPmsDetails: {
	// 	url: `${environment.gatewayBaseUrl}${environment.aaa360}ClientPMSDetails`,
	// 	key: `${environment.paramsAPIKey}`,
	// 	appName: `${environment.boAppName}`
	// }, //same call in "clientPms"
	clientMfDetails: {
		url: `${environment.gatewayBaseUrl}${environment.aaa360}MutualFundDetails`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	clientProductDetails: {
		url: `${environment.gatewayBaseUrl}${environment.aaa360}ClientProductSummary`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	clientFdDetails: {
		url: `${environment.gatewayBaseUrl}${environment.aaa360}GetClientFDDetails`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	clientBonds: {
		url: `${environment.gatewayBaseUrl}${environment.aaa360}GetClientBondDetails`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	clientAif: {
		url: `${environment.gatewayBaseUrl}${environment.aaa360}ClientAIFDetails`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	clientPms: {
		url: `${environment.gatewayBaseUrl}${environment.aaa360}ClientPMSDetails`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	familyMember: {
		url: `${environment.gatewayBaseUrl}${environment.aaa360}AddUpdateDeleteResendVerifyFamilyMapping`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	viewBrokerageRights: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}Get_AAA_ViewBrokerageRight`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	addMenuRights: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}AddUpdateMenuRights`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	clientMappingTableData:{
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}GetClientTTMappingClientStatus`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	mappedClient:{			// cm. check configuration
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}GetMappedClientList`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	demapClient:{			// cm. check configuration
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}DemapClient`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	getQuickLink: {
		url: `${environment.gatewayBaseUrl}${environment.aaaDashboard}FetchQuickLinks`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	addQuickLink: {
		url: `${environment.gatewayBaseUrl}${environment.aaaDashboard}AddDeleteQuickLinks`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`

	},
	crossSellDetails: {
		url: `${environment.gatewayBaseUrl}${environment.aaaDashboard}GetCrossSellDetails`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	mfDashboard: {
		url: `${environment.gatewayBaseUrl}${environment.aaaDashboard}GetMFDashBoard`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	equityDashboard: {
		url: `${environment.gatewayBaseUrl}${environment.aaaGroBox}GetEquityDashBoard`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
		
	},
	overallDashboard: {
		url: `${environment.gatewayBaseUrl}${environment.aaaGroBox}GetOverAllDashboradCount`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	defaultDashboard: {
		url: `${environment.gatewayBaseUrl}${environment.aaaDashboard}GetDefaultDashboard`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	productWiseClient: {
		url: `${environment.gatewayBaseUrl}${environment.aaaDashboard}GetProductWiseClientCount`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	tradingListRepo:{
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}ConsolidateTradingReport`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	commodityScripSum:{
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}CommodityClientScriptSummary`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
		
	},
	vasDetailedReport:{
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}VASDetailReport`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	clientBreakDown: {
		url: `${environment.gatewayBaseUrl}${environment.aaa360}GetClientStockBreakdownDetails`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	GetRMHierarchyNew:{
		url: `${environment.gatewayBaseUrl}${environment.aaaSearch}GetRMHierarchyNew`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`,
		'Ocp-Apim-Subscription-Key': environment.gatewaySubscriptionKey.aaa
	},
	GetClientCodes:{
		url: `${environment.gatewayBaseUrl}${environment.aaaSearch}GetClientCodes`,
		'Ocp-Apim-Subscription-Key': environment.gatewaySubscriptionKey.aaa,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},	
	freezeDetails: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}ListFreezeDetail`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	dpModificationDetails: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}DPModificationReport`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	disInwardDetail: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}DISInwardDetail`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	searchScripDetails: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}SearchScripDetails`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	sessionTransfer: {
		url: `${environment.gatewayBaseUrl}${environment.ttUserActivity}SessionAuthForChatBotLogin`,
		key: 'PMVHB5wpK7LCCYBkl6APNYMqvyNWMZKl'
	},
	wireMappedCode:{
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}FetchWireMappedCode`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	removeClientAccess:{
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}DeactiveWireParentChild`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	kpiDashboardBDM: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}KPIDashBoardBDM`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	epiReqStatus: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}FetchEPIRequest`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	clientDematHoldings: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}FetchClientDematHoldings`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	SubmitEPIRequest: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}SubmitEPIRequest`,
		key: `${environment.paramsAPIKey}`,
appName: `${environment.boAppName}`
	},
	// clientMarginReport: {
	// 	url: `${environment.gatewayBaseUrl}${environment.aaaBase}ClientMarginReport`,
	// 	key: `${environment.paramsAPIKey}`,
	// 	appName: `${environment.boAppName}`
	// }, // not use in code
	accountClosureStatus: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}AccountClosureStatus`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	settlementPayoutReport: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}GetSettlementPayoutReport`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	dpHoldingStatement: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}DPHoldingStatement`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	dematRequestStatus: {			// cm. check configuration
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}GetDematRequestStatus`,
		Key: "446794970C360ab394d176612f8c6",
		appName: 'Customer360'
	},
	offlineIpo: {			// cm. check configuration
		url: `${environment.gatewayBaseUrl}${environment.ipo}IPO/RMLoginthrowOtherApp`,
		'Ocp-Apim-Subscription-Key': 'e6c610d5100f4b37b94381f61045a5f2',
		'Authorization':'IMN05OPLoDvbTTaIQkqLNMI7cPLguaRyHzyg7n5qNBVjQmtBhzF4SzYh4NBVCXi3KJHlSXKP+oi2+bXr6CUYTRL'
	},
	offlineNcd: {			// cm. check configuration
		url: `${environment.gatewayBaseUrl}${environment.ipo}IPO/RMLoginthrowOtherApp`,
		'Ocp-Apim-Subscription-Key': 'e6c610d5100f4b37b94381f61045a5f2',
		'Authorization':'IMN05OPLoDvbTTaIQkqLNMI7cPLguaRyHzyg7n5qNBVjQmtBhzF4SzYh4NBVCXi3KJHlSXKP+oi2+bXr6CUYTRL'
	},
	MFPNLStatement: {			// cm. check configuration
		url: `${environment.gatewayBaseUrl}${environment.aaa360}MFPNLStatementV1`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	getTotalEquityDividend: {
		url: `${environment.gatewayBaseUrl}${environment.aaa360}GetTotalEquityDividendV1`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	clientDetailReport: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}SendClientDetailReport`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	FD_ClientDeatils: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBase}FD_ClientDeatils`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	FD_Online:{
		url: `${environment.gatewayBaseUrl}aaa/api/v1/sso/agent`,
		//url:'https://broking-1sb-cfd-app.azurewebsites.net/api/v1/sso/agent'
	},
	FatchCustomMapping:{
		url: `${environment.gatewayBaseUrl}${environment.aaaDashboard}FatchCustomMapping`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	AddDeleteCustomMapping:{
		url: `${environment.gatewayBaseUrl}${environment.aaaDashboard}AddDeleteCustomMapping`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	getFanPayOutDetails: {
		url: `${environment.gatewayBaseUrl}${environment.aaaDashboard}GetFanPayOutDetails`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	updateInvoiceNo: {
		url: `${environment.gatewayBaseUrl}${environment.aaaDashboard}UpdateFanPayOutInvoiceNo`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	nodeUrl: {
		url:`${environment.gatewayBaseUrl}${environment.aaaSearch}users/download-pdf`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	arnNodeUrl: {
		url:`${environment.gatewayBaseUrl}${environment.aaaSearch}users/web-scrapper`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	AumExcelReportDownload:{
		url: `${environment.gatewayBaseUrl}${environment.aaaReport}AumReport`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	OnlineSIPExcelReportDownload:{
		url: `${environment.gatewayBaseUrl}${environment.aaaDashboard}GetOnlineSIPReport`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	//Invest-Edge 
	investEdge:{
		url: `${environment.gatewayBaseUrl}Accounts/accounts/aaa/request`,
	},
	investcurrentTime:{
		url: `${environment.gatewayBaseUrl}InvestEdge2/masters/current-time`,
	},
	//Optimum
	optimum:{
		url: `${environment.gatewayBaseUrl}Invest/api/ssocontroller/details`,
	},
	downloadInvoicePdf: {
		url: `${environment.gatewayBaseUrl}${environment.aaaBackOffice}reportEmailingv2`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	getNomineeDetails: {
		url: `${environment.gatewayBaseUrl}${environment.aaaDashboard}GetNomineeDetails`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	updateNomineeDetails: {
		url: `${environment.gatewayBaseUrl}${environment.aaaDashboard}AddUpdateNomineeDetails`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	clientWiseGroBoxPortfolio: {
		url: `${environment.gatewayBaseUrl}${environment.aaaGroBox}ClientWiseGroBoxPortfolio`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	getGroTableData: {
		url: `${environment.gatewayBaseUrl}${environment.aaaGroBox}GetClientwiseGroboxAUM`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	GetJVDetails : {
		url : `${environment.gatewayBaseUrl}${environment.aaaJV}`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`,
        appVer: "01",
        osName: "Android",
	},
	getBasketList: {
		url: `${environment.gatewayBaseUrl}${environment.aaaGroBox}GetBasketName`,
		key: `${environment.paramsAPIKey}`,
		appName: `${environment.boAppName}`
	},
	redirectExternalGroboxBasket: {
		appSource: '274',
		url: 'https://grobox-uat.trendlyne.com/grobox/api/auth/login/iifl/dealer/?next=/basket/'
	},
	redirectExternalGroboxInvestment: {
		appSource: '274',
		url:'https://grobox-uat.trendlyne.com/grobox/api/auth/login/iifl/dealer/?next=/account/investment/'
	},
	getConsent: {
		url:`${environment.gatewayBaseUrl}InvestEdge2/investments/external/consent/`
	},
	generateLinkInvestedge: {
		url:`${environment.gatewayBaseUrl}InvestEdge2/investments/external/link`
	},
	generateLinkInvestedgeRm:{
		url:`${environment.gatewayBaseUrl}InvestEdge2/instant-review/link/`
	},
	generateLinkInvestedgeStock:{
		url:`${environment.gatewayBaseUrl}InvestEdge2/investments/external/stocks/refresh`

	},
	getHoldingAndSummary:{
		url:`${environment.gatewayBaseUrl}InvestEdge2/investments/external/`

	},
	ttRefreshToken:{
			url: `${environment.gatewayBaseUrl}${environment.ttUserActivity}RefreshformauthAAA`,
			key: 'dL8mG4UwCfVYjzPWu8Rp'
		},
	trackAssetExternal:{
		url:`${environment.gatewayBaseUrl}InvestEdge2/investments/external/tracker/`

	},

	userActivationToken :{ 
		url : `${environment.gatewayBaseUrl}/eaccount/GetTokenv2`,
		MerchantKey : "3s@nY!mNgOH9p0Td",
		appSource : 'ONLINEWEB',
	},

	modificationStatus : {
		url : `${environment.gatewayBaseUrl}eaccount/Common/GetModificationStatus`,
		appSource : 'OnlineWeb',
		IPAddress : "13.67.71.23",
		MerchantKey : "3s@nY!mNgOH9p0Td"
	},
	
	verifyPan : {
		url : `${environment.panVerify}`,
		appName: `${environment.boAppName}`,
        appVer: "01",
        osName: "Android",
	},

	guestDetails : {
		url : `${environment.gatewayBaseUrl}${environment.aaaDashboard}`,
		key: `${environment.paramsAPIKey}`, 
		appName: `${environment.boAppName}`,
        appVer: "01",
        osName: "Android",
	},

	GuestCaptureDetails : {
		url : `${environment.gatewayBaseUrl}${environment.aaaDashboard}`,
		key: `${environment.paramsAPIKey}`, 
		appName: `${environment.boAppName}`,
        appVer: "01",
        osName: "Android",
	},

	captureGuestPayment : {
		url : `${environment.gatewayBaseUrl}${environment.aaaDashboard}SaveGuestPaymentdetails`,
		key: `${environment.paramsAPIKey}`, 
		appName: `${environment.boAppName}`,
        appVer: "01",
        osName: "Android",
	}
})

// export const SECRET_KEY: string = '$2a$10$e.oPc.dyrwRoQCpDvO9Rhe';