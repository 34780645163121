<ion-header class="main-header">
    <ion-toolbar color="tertiary" class="mobile-visibility" [ngClass]="{'app-view-header': isApkWebView}">
        <div class="title-block title-block-custom">
            <div class="icon-title">
                <ion-icon name="arrow-back-outline" class="back-button" (click)="goBack()"></ion-icon>
                <ion-title class="title">P&L Statement</ion-title>
            </div>
        </div>
    </ion-toolbar>
</ion-header>
<ion-content>
    <div class="container panel-wrapper">
        <div class="header desktop-visibility">
            <img (click)="goBack()" src="../../../assets/svg/back-icon.svg" alt="img">
            <p>P&L Statement</p>
        </div>
        <div class="panel-content mt-0">
            <div class="search_filter_main">
                <div class="search_filter_grid">
                    <div class="label">Select Family Member</div>
                    <div class="select-wrapper client_with_pnl">
                        <div class="select_client"  >
                            <div class="main_select_box" (click)="clientClickpnl()">
                               <div class="name-main">
                                <div>
                                    <div class="short_name">{{getShortName(pnlClientName)}}</div>
                                </div>
                                <div class="title-width">
                                    <span class="clientNameCss">{{pnlClientName}}</span>
                                    <span class="hierarchyType">{{pnlDisplayRelation}}</span>
                                </div>
                               </div>
                                <ion-icon class="family-dropdown" slot="end" name="chevron-down" *ngIf="pnlClientName"></ion-icon>

                            </div>
                            <div class="drop-main" *ngIf="pnldropMain">
                                <div class="select_client_dropdown_main" id="ClientMainBoxPnl" *ngIf="pnlClientName">
                                    <ul class="select_client_dropdown">
                                        <li class="client_box d-flex" *ngFor="let dataObj of familyMappList; let i = index" (click)="pnlDropdownClicked(dataObj)">
                                            <div class="short_name">{{getShortName(dataObj.ClientName ? dataObj.ClientName : "Name Not Available")}}</div>
                                            <div class="name_details">
                                                <span>{{dataObj.ClientName ? dataObj.ClientName : "Name Not Available"}}</span>
                                                <label>{{dataObj.Relation && dataObj.Relation.length > 0 ? dataObj.Relation : 'SELF'}}</label>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="search_filter_grid">
                    <div class="label">Type of Report</div>
                    <ion-segment class="calculator-segment" [(ngModel)]="yearDateOption" (ionChange)="segmentChangedYrDate($event.detail.value)">
                        <ion-segment-button value="yearWise">Year Wise</ion-segment-button>
                        <ion-segment-button value="dateWise">Date Wise</ion-segment-button>
                    </ion-segment>
                </div>

                <div class="section date-section shr_sec_box" *ngIf="yearDateOption !== 'yearWise'">

                    <div class="section inner-section">
                        <div class="label">From Date</div>
                        <div class="datePopover custom-new-calendar">
                            <input matInput readonly [(ngModel)]="ionFromDate" (dateChange)="hideDatePicker('start',$event)" [matDatepicker]="picker" placeholder="DD/MM/YYYY" min="1973-01-01" [max]="currentDate">
                            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </div>
                    </div>

                    <div class="section inner-section">
                        <div class="label">To Date</div>
                        <div class="datePopover custom-new-calendar">
                            <input matInput readonly [(ngModel)]="ionToDate" (dateChange)="hideDatePicker('end',$event)" [matDatepicker]="picker1" placeholder="DD/MM/YYYY" min="1973-01-01" [max]="currentDate">
                            <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
                            <mat-datepicker #picker1></mat-datepicker>
                        </div>

                    </div>

                </div>

                <!-- drop down option for year wise selection -->
                <div class="search_filter_grid">
                    <div class="section date-range-dropdown shr_sec_box" *ngIf="yearDateOption === 'yearWise'">
                        <div class="label">Date Range</div>
                        <ng-select class="date_select_drop" [items]="financialYrList" bindLabel="item" bindValue="value" [loading]="Loadvalue" [clearable]="false" [searchable]="false" [(ngModel)]="yearRangeValue" (change)="selectYrFromList($event)">
                        </ng-select>
                    </div>
                </div>
                <div class="mobile-visibility search_filter_grid plstatement_tab">
                    <div class="label">Report Type</div>
                    <ng-select style="width: 100%;" [items]="productTypeList" bindLabel="option" bindValue="value" [loading]="Loadvalue" [clearable]="false" [searchable]="false" [(ngModel)]="productValue" (change)="panelActiveMobile(productValue)">
                    </ng-select>
                </div>

            </div>
            <div class="tab_type desktop-visibility" *ngIf="productTypeList.length > 0">
                <ul class="panel-content-header">
                    <li *ngFor="let dataObj of productTypeList" 
                    [ngClass]="{'activePanleHead': dataObj.selected }" (click)="panelActive(dataObj.value)" 
                    activepanel="Cash">{{dataObj.option}}</li>
                </ul>
            </div>
            <div class="main_table_deta" *ngIf="tabPanelPnlTable == 'realised' ">
                <app-realised-pnl [callFromDesktop]="true" [realizedParams]="realizedPlObj" *ngIf="realizedPlObj && realizedPlObj?.product != undefined"></app-realised-pnl>
            </div>
            <div class="pnlmf-table" *ngIf="tabPanelPnlTable == 'mf' ">
                <div class="main-table thin-scroll scroll-inner">
                    <div class="table-block header-part">
                        <table class="desktop-table">
                            <thead style="width: 100%">
                                <tr>
                                    <th></th>
                                    <th [class.active]="order === 'Scheme_Name'" (click)="setOrder('Scheme_Name')" class="pointer">Scheme Name
                                        <img class="sorting-img updated-sorting-img" src="/assets/svg/sorting.svg" alt="">
                                        <img class="sorting-img-ascending" src="/assets/svg/sorting_ascending.svg" alt="" *ngIf="ascending">
                                        <img class="sorting-img-descending" src="/assets/svg/sorting_ascending.svg" alt="" *ngIf="!ascending">
                                    </th>
                                    <th [class.active]="order === 'Transaction_Type'" (click)="setOrder('Transaction_Type')" class="pointer">Transaction Type
                                        <img class="sorting-img updated-sorting-img" src="/assets/svg/sorting.svg" alt="">
                                        <img class="sorting-img-ascending" src="/assets/svg/sorting_ascending.svg" alt="" *ngIf="ascending">
                                        <img class="sorting-img-descending" src="/assets/svg/sorting_ascending.svg" alt="" *ngIf="!ascending">
                                    </th>
                                    <th [class.active]="order === 'Transaction_Date'" (click)="setOrder('Transaction_Date')" class="pointer">Transaction Date
                                        <img class="sorting-img updated-sorting-img" src="/assets/svg/sorting.svg" alt="">
                                        <img class="sorting-img-ascending" src="/assets/svg/sorting_ascending.svg" alt="" *ngIf="ascending">
                                        <img class="sorting-img-descending" src="/assets/svg/sorting_ascending.svg" alt="" *ngIf="!ascending">
                                    </th>
                                    <th [class.active]="order === 'Purchase_Price'" (click)="setOrder('Purchase_Price')" class="pointer">Purchase Price
                                        <img class="sorting-img updated-sorting-img" src="/assets/svg/sorting.svg" alt="">
                                        <img class="sorting-img-ascending" src="/assets/svg/sorting_ascending.svg" alt="" *ngIf="ascending">
                                        <img class="sorting-img-descending" src="/assets/svg/sorting_ascending.svg" alt="" *ngIf="!ascending">
                                    </th>
                                    <th [class.active]="order === 'Units'" (click)="setOrder('Units')" class="pointer">Units
                                        <img class="sorting-img updated-sorting-img" src="/assets/svg/sorting.svg" alt="">
                                        <img class="sorting-img-ascending" src="/assets/svg/sorting_ascending.svg" alt="" *ngIf="ascending">
                                        <img class="sorting-img-descending" src="/assets/svg/sorting_ascending.svg" alt="" *ngIf="!ascending">
                                    </th>
                                    <th [class.active]="order === 'Purchase_Amount'" (click)="setOrder('Purchase_Amount')" class="pointer">Purchase Amount
                                        <img class="sorting-img updated-sorting-img" src="/assets/svg/sorting.svg" alt="">
                                        <img class="sorting-img-ascending" src="/assets/svg/sorting_ascending.svg" alt="" *ngIf="ascending">
                                        <img class="sorting-img-descending" src="/assets/svg/sorting_ascending.svg" alt="" *ngIf="!ascending">
                                    </th>
                                    <th [class.active]="order === 'Sell_Date'" (click)="setOrder('Sell_Date')" class="pointer">Sell Date
                                        <img class="sorting-img updated-sorting-img" src="/assets/svg/sorting.svg" alt="">
                                        <img class="sorting-img-ascending" src="/assets/svg/sorting_ascending.svg" alt="" *ngIf="ascending">
                                        <img class="sorting-img-descending" src="/assets/svg/sorting_ascending.svg" alt="" *ngIf="!ascending">
                                    </th>
                                    <th [class.active]="order === 'Sell_Type'" (click)="setOrder('Sell_Type')" class="pointer">Sell Type
                                        <img class="sorting-img updated-sorting-img" src="/assets/svg/sorting.svg" alt="">
                                        <img class="sorting-img-ascending" src="/assets/svg/sorting_ascending.svg" alt="" *ngIf="ascending">
                                        <img class="sorting-img-descending" src="/assets/svg/sorting_ascending.svg" alt="" *ngIf="!ascending">
                                    </th>
                                    <th [class.active]="order === 'Sell_Rate'" (click)="setOrder('Sell_Rate')" class="pointer">Sell Rate
                                        <img class="sorting-img updated-sorting-img" src="/assets/svg/sorting.svg" alt="">
                                        <img class="sorting-img-ascending" src="/assets/svg/sorting_ascending.svg" alt="" *ngIf="ascending">
                                        <img class="sorting-img-descending" src="/assets/svg/sorting_ascending.svg" alt="" *ngIf="!ascending">
                                    </th>
                                    <th [class.active]="order === 'Sell_Amount'" (click)="setOrder('Sell_Amount')" class="pointer">Sell Amount
                                        <img class="sorting-img updated-sorting-img" src="/assets/svg/sorting.svg" alt="">
                                        <img class="sorting-img-ascending" src="/assets/svg/sorting_ascending.svg" alt="" *ngIf="ascending">
                                        <img class="sorting-img-descending" src="/assets/svg/sorting_ascending.svg" alt="" *ngIf="!ascending">
                                    </th>
                                    <th [class.active]="order === 'Days'" (click)="setOrder('Days')" class="pointer">Days
                                        <img class="sorting-img updated-sorting-img" src="/assets/svg/sorting.svg" alt="">
                                        <img class="sorting-img-ascending" src="/assets/svg/sorting_ascending.svg" alt="" *ngIf="ascending">
                                        <img class="sorting-img-descending" src="/assets/svg/sorting_ascending.svg" alt="" *ngIf="!ascending">
                                    </th>
                                    <th [class.active]="order === 'GL_ST_Debt'" (click)="setOrder('GL_ST_Debt')" class="pointer">G/L ST(Debt)(₹)
                                        <img class="sorting-img updated-sorting-img" src="/assets/svg/sorting.svg" alt="">
                                        <img class="sorting-img-ascending" src="/assets/svg/sorting_ascending.svg" alt="" *ngIf="ascending">
                                        <img class="sorting-img-descending" src="/assets/svg/sorting_ascending.svg" alt="" *ngIf="!ascending">
                                    </th>
                                    <th [class.active]="order === 'GL_ST_Equity'" (click)="setOrder('GL_ST_Equity')" class="pointer">G/L ST (Equity) (₹)
                                        <img class="sorting-img updated-sorting-img" src="/assets/svg/sorting.svg" alt="">
                                        <img class="sorting-img-ascending" src="/assets/svg/sorting_ascending.svg" alt="" *ngIf="ascending">
                                        <img class="sorting-img-descending" src="/assets/svg/sorting_ascending.svg" alt="" *ngIf="!ascending">
                                    </th>
                                    <th [class.active]="order === 'GL_LT_Debt'" (click)="setOrder('GL_LT_Debt')" class="pointer">G/L LT(Debt) (₹)
                                        <img class="sorting-img updated-sorting-img" src="/assets/svg/sorting.svg" alt="">
                                        <img class="sorting-img-ascending" src="/assets/svg/sorting_ascending.svg" alt="" *ngIf="ascending">
                                        <img class="sorting-img-descending" src="/assets/svg/sorting_ascending.svg" alt="" *ngIf="!ascending">
                                    </th>
                                    <th [class.active]="order === 'GL_LT_Equity'" (click)="setOrder('GL_LT_Equity')" class="pointer">G/L LT (Equity) (₹)
                                        <img class="sorting-img updated-sorting-img" src="/assets/svg/sorting.svg" alt="">
                                        <img class="sorting-img-ascending" src="/assets/svg/sorting_ascending.svg" alt="" *ngIf="ascending">
                                        <img class="sorting-img-descending" src="/assets/svg/sorting_ascending.svg" alt="" *ngIf="!ascending">
                                    </th>
                                    <th [class.active]="order === 'STT'" (click)="setOrder('STT')" class="pointer">STT (₹)
                                        <img class="sorting-img updated-sorting-img" src="/assets/svg/sorting.svg" alt="">
                                        <img class="sorting-img-ascending" src="/assets/svg/sorting_ascending.svg" alt="" *ngIf="ascending">
                                        <img class="sorting-img-descending" src="/assets/svg/sorting_ascending.svg" alt="" *ngIf="!ascending">
                                    </th>
                                </tr>
                                <tr class="lower-header" *ngIf="consRealizedPlList.length > 0 && tabPanelPnlLoader">
                                    <th></th>
                                    <th>Grand Total</th>
                                    <th>-</th>
                                    <th>-</th>
                                    <th>-</th>
                                    <th>{{grandTotalMFpnl.Units | formatNumerDecimal}}</th>
                                    <th>{{grandTotalMFpnl.Purchase_Amount}}</th>
                                    <th>-</th>
                                    <th>-</th>
                                    <th>-</th>
                                    <th>{{grandTotalMFpnl.Sell_Amount}}</th>
                                    <th>-</th>
                                    <th>{{grandTotalMFpnl.GL_ST_Debt}}</th>
                                    <th>{{grandTotalMFpnl.GL_ST_Equity}}</th>
                                    <th>{{grandTotalMFpnl.GL_LT_Debt}}</th>
                                    <th>{{grandTotalMFpnl.GL_LT_Equity}}</th>
                                    <th>-</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="consRealizedPlList.length > 0 && tabPanelPnlLoader">
                                <tr *ngFor="let dataObj of consRealizedPlList | orderBy: order:sort; let i = index" (click)="dropClick(dataObj.srNo ,consRealizedPlList)">
                                    <td>
                                        <div class="upper-part" [ngStyle]="{'margin-bottom':  dataObj['isVisible'] ? '' + (detailHeight + 1) + 'px': ''}">
                                            <ion-icon *ngIf="!dataObj['isVisible']" class="arrow-button" src="./assets/svg/down-arrow.svg"></ion-icon>
                                            <ion-icon *ngIf="dataObj['isVisible']" class="rotate arrow-button down-arrow-button" src="./assets/svg/down-arrow.svg"></ion-icon>
                                        </div>
                                        <div class="detail-part-fix" #detail *ngIf="dataObj['isVisible']">
                                            <div class="table-block  detail-table-block">
                                                <table class="desktop-table">
                                                    <tbody>
                                                        <tr *ngFor="let detailDataObj of dataObj['Data']; let i = index">
                                                            <td class="width-57"></td>
                                                            <td></td>
                                                            <td>{{detailDataObj['Transaction_Type']}}</td>
                                                            <td>{{detailDataObj['Transaction_Date']}}</td>
                                                            <td>{{detailDataObj['Purchase_Price'] | formatNumerDecimal}}</td>
                                                            <td>{{detailDataObj['Units'] | formatNumerDecimal}}</td>
                                                            <td>{{detailDataObj['Purchase_Amount'] | formatNumerDecimal}}</td>
                                                            <td>{{detailDataObj['Sell_Date']}}</td>
                                                            <td>{{detailDataObj['Sell_Type']}}</td>
                                                            <td>{{detailDataObj['Sell_Rate'] | formatNumerDecimal}}</td>
                                                            <td>{{detailDataObj['Sell_Amount'] | formatNumerDecimal}}</td>
                                                            <td>{{detailDataObj['Days']}}</td>
                                                            <td>{{detailDataObj['GL_ST_Debt'] | formatNumerDecimal}}</td>
                                                            <td>{{detailDataObj['GL_ST_Equity'] | formatNumerDecimal}}</td>
                                                            <td>{{detailDataObj['GL_LT_Debt'] | formatNumerDecimal}}</td>
                                                            <td>{{detailDataObj['GL_LT_Equity'] | formatNumerDecimal}}</td>
                                                            <td>{{detailDataObj['STT'] | formatNumerDecimal}}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="upper-part justify-content-start" [ngStyle]="{'margin-bottom':  dataObj['isVisible'] ? '' + (detailHeight + 1) + 'px': ''}">
                                            {{dataObj['Scheme_Name']}}
                                        </div>
                                    </td>
                                    <td>
                                        <div class="upper-part" [ngStyle]="{'margin-bottom':  dataObj['isVisible'] ? '' + (detailHeight + 1) + 'px': ''}">
                                            -
                                        </div>
                                    </td>
                                    <td>
                                        <div class="upper-part" [ngStyle]="{'margin-bottom':  dataObj['isVisible'] ? '' + (detailHeight + 1) + 'px': ''}">
                                            -
                                        </div>
                                    </td>
                                    <td>
                                        <div class="upper-part" [ngStyle]="{'margin-bottom':  dataObj['isVisible'] ? '' + (detailHeight + 1) + 'px': ''}">
                                            -
                                        </div>
                                    </td>
                                    <td>
                                        <div class="upper-part" [ngStyle]="{'margin-bottom':  dataObj['isVisible'] ? '' + (detailHeight + 1) + 'px': ''}">
                                            {{dataObj['Units'] | formatNumerDecimal}}
                                        </div>
                                    </td>
                                    <td>
                                        <div class="upper-part" [ngStyle]="{'margin-bottom':  dataObj['isVisible'] ? '' + (detailHeight + 1) + 'px': ''}">
                                            {{dataObj['Purchase_Amount'] | formatNumerDecimal}}
                                        </div>
                                    </td>
                                    <td>
                                        <div class="upper-part" [ngStyle]="{'margin-bottom':  dataObj['isVisible'] ? '' + (detailHeight + 1) + 'px': ''}">
                                            -
                                        </div>
                                    </td>
                                    <td>
                                        <div class="upper-part" [ngStyle]="{'margin-bottom':  dataObj['isVisible'] ? '' + (detailHeight + 1) + 'px': ''}">
                                            -
                                        </div>
                                    </td>
                                    <td>
                                        <div class="upper-part" [ngStyle]="{'margin-bottom':  dataObj['isVisible'] ? '' + (detailHeight + 1) + 'px': ''}">
                                            -
                                        </div>
                                    </td>
                                    <td>
                                        <div class="upper-part" [ngStyle]="{'margin-bottom':  dataObj['isVisible'] ? '' + (detailHeight + 1) + 'px': ''}">
                                            {{dataObj['Sell_Amount'] | formatNumerDecimal}}
                                        </div>
                                    </td>
                                    <td>
                                        <div class="upper-part" [ngStyle]="{'margin-bottom':  dataObj['isVisible'] ? '' + (detailHeight + 1) + 'px': ''}">
                                            -
                                        </div>
                                    </td>
                                    <td>
                                        <div class="upper-part" [ngStyle]="{'margin-bottom':  dataObj['isVisible'] ? '' + (detailHeight + 1) + 'px': ''}">
                                            {{dataObj['GL_ST_Debt'] | formatNumerDecimal}}
                                        </div>
                                    </td>
                                    <td>
                                        <div class="upper-part" [ngStyle]="{'margin-bottom':  dataObj['isVisible'] ? '' + (detailHeight + 1) + 'px': ''}">
                                            {{dataObj['GL_ST_Equity'] | formatNumerDecimal}}
                                        </div>
                                    </td>
                                    <td>
                                        <div class="upper-part" [ngStyle]="{'margin-bottom':  dataObj['isVisible'] ? '' + (detailHeight + 1) + 'px': ''}">
                                            {{dataObj['GL_LT_Debt'] | formatNumerDecimal}}
                                        </div>
                                    </td>
                                    <td>
                                        <div class="upper-part" [ngStyle]="{'margin-bottom':  dataObj['isVisible'] ? '' + (detailHeight + 1) + 'px': ''}">
                                            {{dataObj['GL_LT_Equity'] | formatNumerDecimal}}
                                        </div>
                                    </td>
                                    <td>
                                        <div class="upper-part" [ngStyle]="{'margin-bottom':  dataObj['isVisible'] ? '' + (detailHeight + 1) + 'px': ''}">
                                            -
                                        </div>
                                    </td>

                                </tr>
                            </tbody>
                            <tbody>
                                <tr *ngIf="consRealizedPlList.length == 0 && tabPanelPnlLoader">
                                    <td class="no-data-found" colspan="8" style="padding: 10px !important;">No Records Found</td>
                                </tr>
                                <tr>
                                    <td colspan="8" class="text-left-loader" *ngIf="!tabPanelPnlLoader" style="text-align: center; padding: 20px !important">
                                        <img src="/assets/imgs/loader.gif" alt="" width="25px">
                                    </td>
                                </tr>
                            </tbody>

                        </table>

                    </div>
                </div>
            </div>
            <div class="pnlmf-table" *ngIf="tabPanelPnlTable == 'dividend' ">
                <div class="main-table thin-scroll scroll-inner">
                    <div class="table-block header-part" style="width: 100%">
                        <table class="desktop-table" style="width: 100%">
                            <thead style="width: 100%">
                                <tr class="custTd">
                                    <th style="width: 89px !important;padding-left: 8px;">Scrip Name</th>
                                    <th>Date</th>
                                    <th>Amount</th>
                                </tr>
                                <tr class="lower-header">
                                    <th style="padding-left: 8px !important;">Grand Total</th>
                                    <th>-</th>
                                    <th>{{dividendTableList.Total_Dividend_Amount | formatNumerDecimal}}</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="dividendTableList.EquityDividend.length > 0 && dividendTableList.Total_Dividend_Amount != '' && tabPanelPnlLoader">
                                <tr *ngFor="let dataObj of dividendTableList.EquityDividend" style="height: 30px;" class="custTd">
                                    <!-- <td style="padding-left: 5px !important;">{{dataObj['ClientCode']}}</td> -->
                                    <td style="padding-left: 8px !important;">{{dataObj['ScripName']}}</td>
                                    <td>{{moment(dataObj['ExDate']).format('DD/MM/YYYY')}}</td>
                                    <td>{{dataObj['Dividend_Amount'] != "" && dataObj['Dividend_Amount'] != null && dataObj['Dividend_Amount'] != undefined ? (dataObj['Dividend_Amount'] | formatNumerDecimal) : ""}}</td>
                                </tr>
                            </tbody>
                            <tbody>
                                <tr *ngIf="dividendTableList.EquityDividend.length == 0 && tabPanelPnlLoader">
                                    <td class="no-data-found" colspan="8" style="padding: 10px !important;">No Records Found</td>
                                </tr>
                                <tr>
                                    <td colspan="8" class="text-left-loader" *ngIf="!tabPanelPnlLoader" style="text-align: center; padding: 20px !important">
                                        <img src="/assets/imgs/loader.gif" alt="" width="25px">
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

    </div>
</ion-content>