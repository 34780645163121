import { Component, ViewChild, ElementRef, Input, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
	selector: 'app-analytics',
	templateUrl: './analytics.component.html',
	styleUrls: ['./analytics.component.scss'],
})
export class AnalyticsComponent {
	@ViewChild('iframe') iframe!: ElementRef;
	@Input() displayAnalyticsSection: boolean = false;
	isApkWebView = false;
	holdingData:any[] = []
	payloadData: any;
	clientId: any;
	isPartner: any;
	constructor(private route: ActivatedRoute, private router: Router) {
		// if(this.displayAnalyticsSection){
			this.route.queryParams.subscribe((params: any) => {
				this.isApkWebView =  JSON.parse(params?.['isApkWebView']);
				this.clientId =	params?.['code'];
				this.isApkWebView = JSON.parse(params?.['isApkWebView'])
			});
			this.holdingData = localStorage.getItem('totalHoldings') ? JSON.parse(localStorage.getItem('totalHoldings') || "{}") : "[]";
		// }
	}
	ngOnChanges(changes: SimpleChanges): void {
		
	}

	ngOnInit() {
		localStorage.setItem('saveClientId', 'true');
		//this.holdingData = JSON.parse(localStorage.getItem('totalHoldings'));
		// this.loadIframe()
	}

	loadIframe(){
		if(this.holdingData.length > 0){
			// setTimeout(() => {
			if(JSON.parse(localStorage.getItem('equityTabValue') || "{}") == 'True'){
				this.payloadData = JSON.stringify({
					source: "IIFL",
					data: {
						holdings: this.holdingData,
						imported_holdings: true
					},
				});
			}
			else{
				this.payloadData = JSON.stringify({
					source: "IIFL",
					data: {
						holdings: this.holdingData
					},
				});
			}
				
				this.iframe.nativeElement.contentWindow.postMessage(this.payloadData, "*");
	
				// this.iframe.nativeElement.setAttribute('src', "https://newsiifl.trendlyne.com/clientapi/irwin/webview/FHZVJwFXDC/portfolio-report/");
			// }, 1000);
		}
	}
	goBack(){
		localStorage.setItem('fromConnected','true')
    	window.history.back();
    	// this.router.navigate(['client-portfolio'])
	}

	ngOnDestroy(): void {
		//Called once, before the instance is destroyed.
		//Add 'implements OnDestroy' to the class.
		this.iframe;
	}

}
