<div class="guest-journey-main">
<div class="pop_close" (click)="dismiss()"><img src="assets/svg/close_black.svg" width="18" alt="close"></div>

<div class="inner-content">
    <div class="inner-center ion-justify-content-center ion-align-items-center">
        <ng-container *ngIf="commonService.calculateOnboardingProgress(progressVal) == 0; else afterJ">            
            <img src="assets/svg/onboarding-modal-banner-before-start.svg" alt="banner" class="before-journey">
            <div class="before-journey">
                <h2>Get access to client report<br> and more benefits</h2>
            </div>          
        </ng-container>
        <ng-template #afterJ>          
            <img src="assets/svg/onboarding-modal-banner-after-start.svg" alt="banner" class="after-journey">
            <div class="after-journey">
                <h2>Oh, You're Few steps away to<br>become a partner with us</h2>
            </div>
        </ng-template>       
        <span>Complete onboarding process and become a partner with us</span> 
         <div class="orange-btn" (click)="goToDashboard()">Resume Onboarding</div>
    </div> 
</div>
</div>
  
  
